document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-texte-image .swiper");

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, {// eslint-disable-line
      // Optional parameters
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  });
});
